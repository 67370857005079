// app/javascript/packs/custom.js
// レビュー星
document.addEventListener("DOMContentLoaded", () => {
  const starRatingElements = document.querySelectorAll(".star-rating");

  starRatingElements.forEach((starRatingElement) => {
    const rating = parseFloat(starRatingElement.getAttribute("data-rating"));
    const filledStars = Math.floor(rating);
    const halfStar = rating % 1 >= 0.5;

    starRatingElement.querySelectorAll("span").forEach((span, index) => {
      if (index < filledStars) {
        span.classList.add("gold-star");
      } else if (index === filledStars && halfStar) {
        span.style.width = "15px";
        span.classList.add("gold-star");
      }
    });
  });
});

